import React, { Component } from "react"

import styles from "./styles.module.less"

import asset1 from "./img/group-3.png"
import asset2 from "./img/group-3@2x.png"
import asset3 from "./img/group-3@3x.png"

export default class Tech extends Component {
  render() {
    return (
      <section className={styles.Tech}>
        <div className={styles.title}>
          <div className={styles.eye}>Actualizing ideas takes real-world </div>
          TECH
        </div>
        <div>
          <img className={styles.img} src={asset2} srcSet={`${asset2} 3x`} />
        </div>
        <div className={styles.conclusion}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Develop a
          user-friendly site in an easy-to-manage platform. Our team chose
          Squarespace to give all stakeholders the ability to edit and adjust as
          needed.
        </div>
      </section>
    )
  }
}
