import React, { Component } from "react"

import styles from "./styles.module.less"

import phone2 from "./img/phone@2x.png"
import phone3 from "./img/phone@3x.png"

export default class Phone extends Component {
  render() {
    return (
      <section className={styles.Phone}>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.text}>
              And clearly: make the site mobile-responsive. So it&apos;s easy on
              the eyes, and so the younger, internship-seeking audience will ...
              you know, actually use it.
            </div>
            <img className={styles.img} src={phone2} srcSet={`${phone3} 3x`} />
          </div>
        </div>
      </section>
    )
  }
}
