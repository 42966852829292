import React, { Component } from "react"

import styles from "./styles.module.less"

import asset2 from "./img/1-asset-4-d@2x.png"
import asset3 from "./img/1-asset-4-d@3x.png"

export default class Out extends Component {
  render() {
    return (
      <section className={styles.Out}>
        <div className={styles.borderBlock}>
          <h3>How it worked out:</h3>
          <h2>
            With a brand that reflects the people it’s trying to reach, and a
            spiffed-up site to match, applicants grew 30% in 2018.
          </h2>
        </div>
        <div className={styles.subBody1}>
          <div className={styles.text}>
            <div className={styles.font1}>
              876
              <br /> students applied
            </div>
            <div className={styles.font2}>
              435
              <br /> interviewed
            </div>
            <div className={styles.font3}>
              80
              <br /> placed
            </div>
          </div>
          <div>
            <img
              className={styles.asset}
              src={asset2}
              srcSet={`${asset3} 3x`}
            />
          </div>
        </div>
        <div className={styles.conclusion}>
          <div className={styles.h6}>Credits</div>
          <div className={styles.text3}>
            Thanks to the Emerging Leaders Team. AKA the real people who made
            the real work.
            <br />
            <strong>Account Manager:</strong> Daryl Faulkner
            <br />
            <strong>Project Manager:</strong> Matt Monnin
            <br />
            <strong>Strategist:</strong> Cher Fuller
            <br />
            <strong>Designers:</strong> Max Wayt, Rebecca Pierpoint
            <br />
            <strong>Writers:</strong> Tyler Stenson, Elise Choi, Christine
            Miller, Jim Gibbons
            <br />
            <strong>Developers:</strong>Sonya Trachsel, Max&nbsp;Wayt
          </div>
        </div>
      </section>
    )
  }
}
