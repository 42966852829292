import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.less"

const Bottom = () => {
  return (
    <section data-component="Bottom" className={styles.Bottom}>
      <div className={styles.bg}>
        <div className={styles.cta}>
          <Link to="/case-studies/be">
            <span className={styles.left}>Previous</span>
          </Link>
          <Link to="/case-studies/oregon-college-savings-plan">
            <span className={styles.right}>Read next</span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Bottom
