import React, { Component } from "react"

import styles from "./styles.module.less"

import logo1 from "./img/1-asset-3-d.png"
import logo2 from "./img/1-asset-3-d@2x.png"
import logo3 from "./img/1-asset-3-d@3x.png"
import dsc1 from "./img/dsc-0837.png"
import dsc2 from "./img/dsc-0837@2x.png"
import dsc3 from "./img/dsc-0837@3x.png"

export default class Strategy extends Component {
  render() {
    return (
      <section className={styles.Strategy}>
        <div className={styles.title}>
          <div>Strong results start with a strong</div>
          <h2>STRATEGY</h2>
          <div>
            Show up in a way that&apos;s both real and representative of the
            change you&apos;re committed to making.
          </div>
        </div>
        <div>
          <img className={styles.img} src={dsc2} srcSet={`${dsc3} 3x`} />
          <img className={styles.logo} src={logo2} srcSet={`${logo3} 3x`} />
        </div>
        <div className={styles.conclusion}>
          This means getting to know your applicants, so you can deliver, with
          certainty, what will make them feel seen.
        </div>
      </section>
    )
  }
}
