import React, { Component } from "react"

import styles from "./styles.module.less"

import el from "./img/logo-big-main-copy@3x.png"
import logo2 from "./img/blue-logo@2x.png"
import logo3 from "./img/blue-logo@3x.png"
import slead2 from "./img/1-asset-1-d@2x.png"
import slead3 from "./img/1-asset-1-d@3x.png"
import leader2 from "./img/2018-el-wk-event-selects-15@2x.png"
import leader3 from "./img/2018-el-wk-event-selects-15@3x.png"
import seeing2 from "./img/dsc-0496@2x.png"
import seeing3 from "./img/dsc-0496@3x.png"

export default class Support extends Component {
  render() {
    return (
      <section className={styles.Supports}>
        <div className={styles.relative}>
          <div className={styles.hero}>
            <div className={styles.textsm}>CASE STUDY: EMERGING LEADERS</div>
            <div className={styles.textlg}>
              Support for diversity should look diverse.
            </div>
          </div>
        </div>

        <div className={styles.logo}>
          <div className={styles.outer}>
            <div className={styles.inner}>
              <img src={el} srcSet={`${el} 3x`} />
            </div>
          </div>
          <div className={styles.center}>
            <div className={styles.text}>
              <div className={styles.title}>
                A new identity for Emerging Leaders.
              </div>
              <div className={styles.content}>
                You&apos;re offering more than a foot in the
                door&#8212;you&apos;re offering a step toward leadership for
                students of color. You need a brand identity and site that are
                as impactful as your mission.
              </div>
            </div>
          </div>
          <div>
            <div className={styles.markLeader}>
              <img src={slead2} srcSet={`${slead3} 3x`} />
            </div>
            <div className={styles.imgLeader}>
              <img src={leader2} srcSet={`${leader3} 3x`} />
            </div>
          </div>
          <div className={styles.seeing}>
            <div className={styles.img}>
              <img src={seeing2} srcSet={`${seeing3} 3x`} />
            </div>
          </div>
          <div className={styles.borderBlock}>
            <h3>How to get after it:</h3>
            <h2>Remove barriers to entry.</h2>
            <h2>
              Create a new, branded website for students, employers, and
              potential mentors that reflects the access and inclusion that
              Emerging Leaders provides.
            </h2>
          </div>
        </div>
      </section>
    )
  }
}
