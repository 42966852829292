import React from "react"

import ELIcase from "../../components/CaseStudies/ELIcase"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

const EliCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="" isArchive>
    <SEO
      title="Thesis | Case study: Emerging Leaders"
      description="We created a new website for Emerging Leaders, reflecting their brand goals to provide students of color with pathways to leadership. Read the case study."
    />

    <ELIcase />
  </Layout>
)

export default EliCaseStudy
