import React, { Component } from "react"

import styles from "./styles.module.less"

import asset2 from "./img/1-asset-1-d@2x.png"
import asset3 from "./img/1-asset-1-d@3x.png"
import mockup2 from "./img/mockupweb-1@2x.png"
import mockup3 from "./img/mockupweb-1@3x.png"
import bluelogo2 from "./img/blue-logo@2x.png"
import bluelogo3 from "./img/blue-logo@3x.png"
import redlogo2 from "./img/red-logo@2x.png"
import redlogo3 from "./img/red-logo@3x.png"

export default class Creative extends Component {
  render() {
    return (
      <section className={styles.Creative}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles.h2}>
              <div className={styles.eye}>
                Best-laid plans thrive with best-best{" "}
              </div>{" "}
              <br />
              CREATIVE
            </div>
          </div>

          <div className={styles.subBody1}>
            <div className={styles.text}>
              <div className={styles.h4}>
                &quot;I love this. I love the colors. I love the colors on the
                pictures. I&apos;m blown away.&quot;
              </div>
              <div className={styles.h6}>
                &#8212;Cinthia Manuel
                <br />
                Director of Workforce Initiatives
              </div>
            </div>
          </div>

          <div className={styles.img1}>
            <img src={mockup2} srcSet={`${mockup3} 3x`} />
          </div>

          <div className={styles.subBody2}>
            <img src={asset2} srcSet={`${asset3} 3x`} />
            <div className={styles.par}>
              {" "}
              <p className={styles.text2}>
                <strong>Visuals</strong> charged with the vibrancy and diversity
                tomorrow’s leaders bring to the table.
              </p>
            </div>
          </div>
          <div className={styles.subBody3}>
            <img src={redlogo2} srcSet={`${redlogo3} 3x`} />
            <div className={styles.par}>
              {" "}
              <p className={styles.text2}>
                <strong>Voice</strong> that keeps it real while connecting with
                your three unique audiences.
              </p>
            </div>
          </div>
          <div className={styles.subBody4}>
            <img src={bluelogo2} srcSet={`${bluelogo3} 3x`} />
            <div className={styles.par}>
              {" "}
              <p className={styles.text3}>
                <strong>A self-aware brand identity</strong> that helps its
                audience feel seen and heard.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
